import { Link } from "react-router-dom";

export default function CTAButton({
    title = "בואו נתחיל",
    isSmall = false,
    outline = false,
}: {
    title?: string;
    isSmall?: boolean;
    outline?: boolean;
}) {
    return (
        <Link
            className={`px-3 py-1.5 ${
                isSmall ? "w-24" : "w-60 text-lg"
            } block text-center rounded-full
            ${
                outline
                    ? "bg-white border border-green-500 text-green-500 hover:bg-slate-50"
                    : "bg-green-500 text-white hover:bg-green-600"
            }
             shadow-md transition-all hover:-translate-y-1 hover:shadow-xl`}
            target="_blank"
            rel="noopener noreferrer"
            to="/join"
        >
            {title}
        </Link>
    );
}
