import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import demoImage from "../assets/images/in-super-demo.png";
import demoImage3 from "../assets/images/webapp-demo.png";
import demoImage2 from "../assets/images/whats-left-btns-demo.png";
import CTAButton from "../components/CTAButton";

export default function Home() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    return (
        <div className="relatvie pb-20 lg:pb-10 flex flex-col lg:flex-row justify-between gap-6 lg:gap-10 h-full items-center lg:items-start mx-auto lg:max-w-[800px]">
            <BgPattern />
            <div className="text-center relative z-10 lg:text-start mt-10 lg:mt-20 2xl:mt-32 flex flex-col gap-5 items-center lg:items-start">
                <div>
                    <h5 className="text-base text-green-500 font-medium">
                        רשימת קניות, אבל בוואטסאפ
                    </h5>
                    <h1 className="font-poppins text-5xl lg:text-6xl font-medium">
                        Groceroo
                    </h1>
                </div>
                <p className="text-lg lg:w-4/5">
                    מוסיפים פריטים, משתפים עם מי שרוצים, מסדרים את הרשימה{" "}
                    <strong>והכל בתוך הוואטסאפ</strong>
                </p>
                <ul className="mt-2 flex flex-col gap-1 text-start list-disc">
                    <li>חלוקה אוטומטית לקטגוריות</li>
                    <li>אתר לסימון ועריכה בנוחות</li>
                    <li>צירוף בני משפחה וחברים לרשימה</li>
                </ul>
                <div className="mt-3">
                    <CTAButton title="להצטרפות" />
                    {/* <span className="text-slate-500 text-[12px] mt-2">
                        ללא תשלום. ללא הרשמה.
                    </span> */}
                </div>
            </div>
            <Carousel
                className="h-full w-full z-10 relative"
                swipeable={true}
                draggable={true}
                responsive={responsive}
                showDots={false}
                infinite={true}
                autoPlay={true}
                pauseOnHover={true}
                autoPlaySpeed={10000}
                keyBoardControl={false}
                transitionDuration={500}
                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            >
                <img
                    src={demoImage}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage2}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px] lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage3}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage2}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px] lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage3}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage2}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px] lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage3}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage2}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px] lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage3}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage2}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px] lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage3}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage2}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px] lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage3}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage2}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px] lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
                <img
                    src={demoImage3}
                    alt="Groceroo demo"
                    className="mx-auto h-[630px lg:h-[530px] 2xl:h-[630px] mt-4 lg:mt-10"
                />
            </Carousel>
        </div>
    );
}

const BgPattern = () => (
    <svg
        className="absolute right-full translate-x-1/4 translate-y-[35%] lg:translate-y-0 transform lg:translate-x-[100%] opacity-50"
        width={404}
        height={584}
        fill="none"
        viewBox="0 0 404 784"
    >
        <defs>
            <pattern
                id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
            >
                <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                />
            </pattern>
        </defs>
        <rect
            width={404}
            height={784}
            fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)"
        />
    </svg>
);
