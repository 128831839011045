export default function ListProgressBar({ progress }: { progress: number }) {
    return (
        <div className="w-full bg-slate-100 rounded-full h-2.5 mb-0">
            <div
                className="bg-green-500 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${progress * 100}%` }}
            ></div>
        </div>
    );
}
