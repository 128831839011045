import { Dialog, Transition } from "@headlessui/react";
import { LightningBoltIcon } from "@heroicons/react/outline";
import Hotjar from "@hotjar/browser";
import Link from "components/Link";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import adsDemo from "../assets/images/features-demo/ads.svg";
import catsOrderDemo from "../assets/images/features-demo/category-order.svg";
import commentsDemo from "../assets/images/features-demo/comments-premium.svg";
import inChatEditDemo from "../assets/images/features-demo/edit-premium.svg";
import multipleListsDemo from "../assets/images/features-demo/multiple-lists.svg";
import noLimitsDemo from "../assets/images/features-demo/no-limits.svg";
import quantitiesDemo from "../assets/images/features-demo/quantities-premiun.svg";
import savedItemsDemo from "../assets/images/features-demo/saved-items.svg";
import splitDemo from "../assets/images/features-demo/split-list.svg";
import typosDemo from "../assets/images/features-demo/typos.svg";
import whoAddedDemo from "../assets/images/features-demo/who-added-that.svg";
import { db } from "../firebase";
import useUserId from "../hooks/useUserId";
import { CheckoutCard } from "./checkout";

const siteId = 5135423;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

export const logEvent = async (
    type: string,
    userId: string | null,
    params?: any
) => {
    try {
        if (userId) {
            const userEventsDocRef = doc(db, "events", userId);

            const data = await getDoc(userEventsDocRef);
            const events = data?.data()?.events ?? [];
            return setDoc(userEventsDocRef, {
                // return setDoc(doc(db, "events", phoneNumber ?? uuid()), {
                events: [...events, { type, params, date: new Date() }],
            });
        }
    } catch (err) {
        console.error(err);
        return setDoc(doc(db, "errors", uuid()), {
            error: JSON.stringify(err),
        });
    }
};

export default function PremiumInfo() {
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAsList, setShowAsList] = useState(false);
    const phoneNumber = searchParams.get("phone");
    const { userId } = useUserId({ phoneNumber });

    useEffect(() => {
        logEvent("landing", userId, {});
    }, [userId]);

    const premiumFeatures = [
        {
            title: "3 רשימות נפרדות",
            description:
                "משתמשי Plus מקבלים עוד 2 רשימות נפרדות, שאפשר לעבור בינהן ולנהל במקביל",
            imgSrc: multipleListsDemo,
            imgAlt: "multipleListsDemo",
        },
        {
            title: "פריטים שמורים וקבועים",
            description:
                'קונים את אותם דברים כל שבוע? תוכלו להגדיר חלק מהפריטים ברשימה כ"קבועים" ולהוסיף אותם לרשימה בפקודה אחת',
            imgSrc: savedItemsDemo,
            imgAlt: "savedItemsDemo",
        },
        {
            title: "תמיכה בכמויות",
            description:
                'ניתן לצרף כמויות לפריטים ולנהל את הכמות בקלות, ע"י פקודות מיוחדות שיש רק למשתמשי Plus',
            imgSrc: quantitiesDemo,
            imgAlt: "quantitiesDemo",
        },
        {
            title: "עריכת פריטים בתוך הצ'אט",
            description:
                "ערכו את שם הפריט ישר בתוך השיחה, עם פקודות יעודית במיוחד בשביל זה",
            imgSrc: inChatEditDemo,
            imgAlt: "inChatEditDemo",
        },
        {
            title: "שימוש ללא הגבלה",
            description:
                "ניתן להוסיף כמה משתתפים שרוצים לכל הרשימות, להשתמש בכפתורי השיחה ללא הגבלה ו-100% מהפריטים שלכם מקוטלגים אוטומטית",
            imgSrc: noLimitsDemo,
            imgAlt: "noLimitsDemo",
            withPremiumBadge: true,
        },
        // {
        //     title: "סימון פריט כחסר",
        //     description:
        //         'המוצר חסר בסופר? אין בעיה - פשוט מסמנים אותו כ"חסר" והבוט כבר ידע להתמודד עם זה',
        //     imgSrc: multipleListsDemo,
        //     imgAlt: "multipleListsDemo",
        // },
        {
            title: "בלי פרסומות. בלי לחכות. אף פעם.",
            description:
                'משתמשי Plus לא רואים פרסומות ומקבלים עדיפות בהכנת הרשימות ע"י הבוט, ככה שהם לא צריכים לחכות',
            imgSrc: adsDemo,
            imgAlt: "adsDemo",
            withPremiumBadge: true,
        },
        {
            title: "הוספת הערות לפריטים",
            description:
                "ניתן להוסיף הערות לכל פריט, בתוך סוגריים שהבוט יודע להתעלם מהן",
            imgSrc: commentsDemo,
            imgAlt: "commentsDemo",
        },

        {
            title: "חלוקת הרשימה ל-2",
            description:
                "אתם הולכים כזוג לסופר? פצלו את הרשימה שלכם ל-2 וכל אחד יתנהל מול הרשימה שלו, בלי להתנגש ובלי לראות את הפריטים של השני בזמן הקנייה",
            imgSrc: splitDemo,
            imgAlt: "splitDemo",
        },
        {
            title: "מי הוסיף את הדבר הזה?!",
            description:
                'משתמשי Plus יכולים לראות מי הוסיף כל פריט לרשימה שלהם, במקום לצעוק בכל הבית: "מי הוסיף קורנפלקס כריות?!"',
            imgSrc: whoAddedDemo,
            imgAlt: "whoAddedDemo",
        },
        {
            title: "לסדר את הרשימה כמו שאתם רוצים",
            description:
                "תוכלו לשנות את סדר הקטגוריות ברשימה לפי איך שהסופר שלכם בנוי, בהתאמה אישית",
            imgSrc: catsOrderDemo,
            imgAlt: "catsOrderDemo",
        },
        {
            title: "תמיכה בשגיאות כתיב",
            description:
                'כתבתם "מחר" במקום "מחק"? אין בעיה, הבוט מבין מה משתמשי Plus רוצים.\nכן, הוא אפילו יבין שרציתם לסמן את העגבניות כשכתבתם "סמן עגבנייה"',
            imgSrc: typosDemo,
            imgAlt: "typosDemo",
        },
    ];

    return (
        <div className="pb-10 mt-4 font-assistant" dir="rtl">
            <SuccessSignUpForm
                isOpen={openSuccessPopup}
                setIsOpen={setOpenSuccessPopup}
            />
            <div className="pt-2 pb-3 flex flex-col w-full pl-4 pr-7 lg:px-20 gap-1.5">
                <div className="flex flex-col gap-10 w-full max-w-[400px] justify-between items-center mt-4">
                    <h1 className="font-assistant text-4xl text-start font-semibold w-full">
                        פיצ'רים גדולים
                        <div className="flex gap-2 items-center">
                            מחיר קטן
                            <LightningBoltIcon className="w-7 h-7 text-amber-300" />{" "}
                        </div>
                    </h1>
                    <p className="font-assistant text-2xl text-gray-600 font-medium">
                        תוציאו 100% מהרשימה שלכם, עם כל הפקודות שאתם צריכים.
                        <br />
                        צריכים עוד רשימות? קיבלתם.
                        {/* <br />
                        <span className="font-semibold text-black">
                            רק ₪5.90 לחודש
                        </span> */}
                    </p>
                    <div className="flex flex-col gap-1 w-full">
                        <Link
                            to={`/checkout/?phone=${phoneNumber}&pay=true`}
                            className="flex gap-2 items-center justify-center text-xl px-4 py-2 w-full font-semibold text-center rounded-full shadow-sm text-white bg-green-500 hover:bg-green-600 disabled:bg-slate-300 disabled:cursor-not-allowed"
                        >
                            {/* ₪5.90 לחודש. חודש ראשון חינם. */}
                            לשדרוג ב-₪5.90 לחודש
                            {/* נסו עם חודש ניסיון בחינם */}
                        </Link>
                        {/* <span className="text-center text-sm text-gray-500">
                            החזר כספי מלא עד 60 ימים מרגע ההצטרפות
                        </span> */}
                    </div>
                </div>
            </div>
            <div className="mt-10 relative pt-2 pb-20">
                <button
                    className="flex gap-1.5 items-center justify-end w-full text-slate-500 px-2 pb-2"
                    onClick={() => setShowAsList((prev) => !prev)}
                >
                    הצג:{" "}
                    {!showAsList ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                        </svg>
                    )}
                </button>
                <div className="h-[500px] lg:h-[450px] xs:h-[600px] opacity-40 w-[95vw] bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#64e843a3] from-[28%] via-[#53da67bb] via-[70%] to-[#60f060] rounded-[2.25rem] ring-1 ring-black/5 z-0 absolute top-24 lg:top-24 right-1 sm:right-3" />
                {showAsList ? (
                    <div className="relative z-10 px-5 lg:mr-20 lg:pl-40">
                        <CheckoutCard isDiscount={false} />
                    </div>
                ) : (
                    <div className="relative py-2 z-10 flex gap-5 lg:gap-10 w-screen scorllbar lg:pb-5 px-3 sm:px-5 mobile-md:px-7 lg:pl-[4.5rem] lg:pr-[4.5rem] overflow-x-auto">
                        {premiumFeatures.map((feature) => (
                            <FeatureCard
                                key={feature.title}
                                title={feature.title}
                                description={feature.description}
                                imgSrc={feature.imgSrc}
                                imgAlt={feature.imgAlt}
                                withPremiumBadge={feature.withPremiumBadge}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

const FeatureCard = ({
    title,
    description,
    imgSrc,
    imgAlt,
    withPremiumBadge,
}: {
    title: string;
    description: string;
    imgSrc: string;
    imgAlt: string;
    withPremiumBadge?: boolean;
}) => {
    return (
        <div
            className={`text-center w-64 xs:w-72 sm:w-[19rem] mobile-sm:w-[19.5rem] mobile-md:w-80 h-[500px] xs:h-[600px] flex-shrink-0 shadow-black/5 rounded-[2rem] shadow-[inset_0_0_2px_1px_#ffffff4d] p-2 ring-1 ring-black/5`}
            dir="rtl"
        >
            <div className="flex flex-col gap-8 bg-white ring-1 shadow-md ring-black/5 rounded-3xl h-full w-full p-4 xs:p-6 pb-9">
                <div className="flex flex-col gap-1">
                    <h3 className="text-2xl font-semibold">{title}</h3>
                </div>
                <img
                    className="h-[240px] xs:h-[340px] w-[160px] xs:w-[235px] mx-auto rounded-lg mb-0"
                    src={imgSrc}
                    alt={imgAlt}
                />
                {/* </div> */}
                <p className="text-base text-start">{description}</p>
            </div>
        </div>
    );
};

function SuccessSignUpForm({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: any;
}) {
    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                dir="rtl"
                as="div"
                className="relative z-10 font-assistant"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-slate-900/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-6 shadow-xl transition-all">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-col items-center pb-4"
                                >
                                    <button
                                        onClick={closeModal}
                                        className="self-end focus:outline-none"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-5 h-5"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18 18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <div className="flex gap-2 items-center justify-center">
                                        {/* <Logo /> */}
                                        <h3 className="font-semibolf text-2xl">
                                            רשמתי אותך
                                        </h3>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-8 h-8 text-green-500"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 12.75 6 6 9-13.5"
                                            />
                                        </svg>
                                    </div>
                                </Dialog.Title>
                                {
                                    <div className="text-xl">
                                        ניידע אותך כשנתחיל להפעיל את מערכת הPlus
                                        <button
                                            onClick={closeModal}
                                            className="mt-2 text-xl px-4 py-3 w-full font-medium text-center rounded-md shadow-sm text-white bg-green-500"
                                        >
                                            תודה!
                                        </button>
                                    </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
