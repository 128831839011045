import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import TrashIcon from "components/icons/TrashIcon";
import { deleteItemFromDB, updateItemInDB } from "db-utils";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import useList from "hooks/useList";
import React, { Fragment, useEffect, useState } from "react";
import { useLongPress } from "use-long-press";
import { getUnitShortText } from "utils";
import { CATEGORIES, getCategoryById } from "../../categories";
import { Item, LIST_TYPES } from "../../types";
import SelectCategoryPopup from "../list/SelectCategoryPopup";

export default function ListItem({
    text,
    checked,
    categoryId,
    quantity,
    unit,
    comments,
    saved,
    addedBy,
    ...restOfItem
}: Item) {
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const { listData } = useList();
    // const { user } = useUser();
    const { isAlerted } = useAlert();
    const { isConfirmed } = useConfirm();

    const onLongPress = useLongPress(
        () => {
            if (navigator.vibrate) {
                navigator.vibrate(50);
            }
            setOpenEditDrawer(true);
        },
        {
            threshold: 500,
        }
    );

    if (!listData) return null;

    const onCheckItem = async () => {
        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                checked: !checked,
            },
        });
    };

    const onCategorySelect = async ({ categoryId }: { categoryId: string }) => {
        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                categoryId,
            },
        });
    };

    const onDeleteItem = async () => {
        if (await isConfirmed("בטוח למחוק את הפריט?")) {
            deleteItemFromDB({
                itemId: text,
                listId: listData.id,
                existingItems: listData.items,
            });
        }
    };

    const onNameChangeSubmit = async ({ newName }: { newName: string }) => {
        if (!newName.trim() || newName.trim() === text) {
            setOpenEditDrawer(false);
            return;
        }

        const nameAlreadyExists = listData.items.some(
            (item) => item.text === newName
        );

        if (nameAlreadyExists) {
            await isAlerted("פריט בשם זה כבר קיים ברשימה");
            return;
        }

        const isNewTextNumeric = !isNaN(Number(newName));

        if (isNewTextNumeric) {
            await isAlerted("שם הפריט לא יכול להיות ספרות בלבד");
            return;
        }

        const isNewTextListWord = newName === "רשימה";
        if (isNewTextListWord) {
            await isAlerted('שם הפריט לא יכול להיות "רשימה"');
            return;
        }

        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                text: newName,
            },
        });
    };

    const { type } = listData;
    const isPrimaryList = type === LIST_TYPES.primary;

    return (
        <>
            <li
                className={`flex cursor-pointer items-center justify-between h-[3.95rem] py-3 pr-1.5 w-full ${
                    checked ? "opacity-30" : ""
                } gap-1`}
            >
                <button
                    className={`${
                        isPrimaryList ? "w-[75%]" : "w-[85%]"
                    } flex gap-0 items-center h-full`}
                    {...onLongPress()}
                    onClick={onCheckItem}
                >
                    <div
                        className={`${
                            checked
                                ? "border-green-500 bg-green-500"
                                : "border-slate-400/70 bg-white"
                        }  rounded-[0.25rem] w-5 border-[1.4px] ml-3 h-5 flex items-center justify-center`}
                    >
                        {checked && (
                            <CheckIcon className="w-5 h-5 text-white" />
                        )}
                    </div>
                    <div
                        className={`inline text-start cursor-pointer break-keep leading-[34px] items-center max-w-[80vw] whitespace-nowrap w-66 h-full overflow-auto transition-all ${
                            checked ? "text-slate-800 line-through" : ""
                        }`}
                    >
                        {text}{" "}
                        {comments ? (
                            <span className="text-lg">
                                (<span className="italic">{comments}</span>)
                            </span>
                        ) : null}
                    </div>
                </button>

                <div className={`flex items-center gap-1`}>
                    {quantity ? (
                        <div className="w-8 text-center text-xl text-slate-500 font-poppins">
                            {quantity}
                            {unit ? (
                                <span className="text-base text-slate-400 font-assistant pr-0.5">
                                    {getUnitShortText(unit).substring(
                                        0,
                                        getUnitShortText(unit).includes('"')
                                            ? 3
                                            : 2
                                    )}
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                    <SelectCategoryPopup
                        show={isPrimaryList}
                        button={
                            <div
                                className={`${
                                    checked ? "" : " m-auto rounded-full"
                                } text-2xl w-8 h-8 p-1 flex items-center justify-center`}
                            >
                                {
                                    getCategoryById({
                                        id: categoryId,
                                    }).icon
                                }
                            </div>
                        }
                        categoryOptions={Object.values(CATEGORIES)}
                        onSelect={onCategorySelect}
                    />
                </div>
            </li>
            <PartialEditItemModal
                open={openEditDrawer}
                setOpen={setOpenEditDrawer}
                itemText={text}
                onNameChangeSubmit={onNameChangeSubmit}
                onDeleteItem={onDeleteItem}
            />
        </>
    );
}

const PartialEditItemModal = ({
    open,
    setOpen,
    itemText,
    onNameChangeSubmit,
    onDeleteItem,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    itemText: string;
    onNameChangeSubmit: ({ newName }: { newName: string }) => void;
    onDeleteItem: () => void;
}) => {
    const [newName, setNewName] = useState(itemText);
    const [deleteDisabled, setDeleteDisabled] = useState(true);

    const inputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (open) {
            inputRef.current?.focus();
        }
        setTimeout(() => {
            setDeleteDisabled(false);
        }, 1000);
    }, [open]);

    return (
        <Transition show={open}>
            <Dialog
                dir="rtl"
                as="div"
                className="font-assistant relative z-50"
                // onClose={() => setOpenEditDrawer(false)}
                onClose={() => {}}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                as="div"
                                className="w-full h-full flex flex-col justify-between gap-2 max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-start align-middle shadow-xl transition-all"
                            >
                                <Dialog.Title
                                    as="div"
                                    className="flex items-center justify-center"
                                >
                                    <h3 className="text-xl font-semibold text-center pb-3">
                                        עריכת הפריט
                                    </h3>
                                    <button
                                        type="button"
                                        className="h-7 w-7 absolute top-4 left-4"
                                    >
                                        <XIcon onClick={() => setOpen(false)} />
                                    </button>
                                </Dialog.Title>
                                <form
                                    className="flex flex-col gap-2"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (newName.trim() === itemText) {
                                            setOpen(false);
                                            return;
                                        }
                                        onNameChangeSubmit({
                                            newName: newName.trim(),
                                        });
                                    }}
                                >
                                    <div className="flex text-lg gap-1  h-12 items-center flex-row-reverse">
                                        <input
                                            ref={inputRef}
                                            className="border-2 h-full text-slate-800 border-slate-200 rounded-lg p-2 w-full"
                                            type="text"
                                            value={newName}
                                            onChange={(e) => {
                                                setNewName(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <button
                                        className="text-lg w-full mt-5 gap-2 font-medium border-[1.5px] border-green-500 bg-green-500 text-white disabled:bg-slate-300 disabled:border-slate-300 rounded-lg shadow-sm px-3 py-2 flex items-center justify-center"
                                        type="submit"
                                        disabled={!newName.trim()}
                                    >
                                        שמירה <CheckIcon className="w-5 h-5" />
                                    </button>
                                    <button
                                        className="text-lg w-full mt-1 gap-2 font-medium border-[1.5px] border-red-500 bg-white text-red-500 rounded-lg shadow-sm px-3 py-2 flex items-center justify-center"
                                        type="button"
                                        disabled={deleteDisabled}
                                        onClick={onDeleteItem}
                                    >
                                        מחיקה <TrashIcon className="w-5 h-5" />
                                    </button>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
