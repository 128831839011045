import { BookmarkIcon } from "@heroicons/react/outline";
import { deleteItemFromDB, updateItemInDB } from "db-utils";
import useAlert from "hooks/useAlert";
import useList from "hooks/useList";
import useUser from "hooks/useUser";
import { useState } from "react";
import { getUnitShortText, isPremium } from "utils";
import { CATEGORIES, getCategoryById } from "../../categories";
import TrashIcon from "../../components/icons/TrashIcon";
import { Item, LIST_TYPES } from "../../types";
import EditItemDrawer from "./EditItemDrawer";
import SelectCategoryPopup from "./SelectCategoryPopup";

export default function EditListItem({
    text,
    checked,
    categoryId,
    quantity,
    unit,
    comments,
    saved,
    addedBy,
    ...restOfItem
}: Item) {
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const { listData } = useList();
    const { user } = useUser();
    const { isAlerted } = useAlert();

    if (!listData) return null;

    const updatedIsSavedItem = async () => {
        const isUserPremium = isPremium({
            type: user?.userData?.accountType,
        });

        if (!isUserPremium) {
            isAlerted("רק משתמשי Plus ⚡ יכולים לשמור פריטים");
            return;
        }

        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                saved: !saved,
            },
        });
    };

    const onCategorySelect = async ({ categoryId }: { categoryId: string }) => {
        await updateItemInDB({
            listId: listData.id,
            itemId: text,
            existingItems: listData.items,
            existingSavedItems: listData.savedItems,
            updates: {
                categoryId,
            },
        });
    };

    const onDeleteItem = async () => {
        deleteItemFromDB({
            itemId: text,
            listId: listData.id,
            existingItems: listData.items,
        });
    };

    const { type } = listData;
    const isPrimaryList = type === LIST_TYPES.primary;

    return (
        <>
            <li
                className={`flex cursor-pointer items-center justify-between h-[3.95rem] py-3 pr-1.5 w-full gap-1`}
            >
                <button
                    className={`${
                        isPrimaryList ? "w-[50%]" : "w-[60%]"
                    } flex gap-0 items-center h-full `}
                    onClick={() => setOpenEditDrawer(true)}
                >
                    <div
                        className={`inline text-start cursor-pointer underline decoration-1 break-keep items-center max-w-[80vw] whitespace-nowrap w-66 h-full overflow-auto transition-all ${
                            checked ? "text-slate-800 line-through" : ""
                        }`}
                    >
                        {text}{" "}
                        {comments ? (
                            <span className="text-lg">
                                (<span className="italic">{comments}</span>)
                            </span>
                        ) : null}
                    </div>
                </button>

                <div className={`flex items-center gap-3`}>
                    {quantity ? (
                        <div className="w-8 text-center text-xl text-slate-500 font-poppins">
                            {quantity}
                            {unit ? (
                                <span className="text-base text-slate-400 font-assistant pr-0.5">
                                    {getUnitShortText(unit).substring(
                                        0,
                                        getUnitShortText(unit).includes('"')
                                            ? 3
                                            : 2
                                    )}
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                    <button
                        onClick={updatedIsSavedItem}
                        type="button"
                        className={`w-7 h-7 font-thin`}
                    >
                        {saved ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-full h-7 text-amber-300"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ) : (
                            <BookmarkIcon className="text-slate-800 h-7 w-full" />
                        )}
                    </button>

                    <SelectCategoryPopup
                        show={isPrimaryList}
                        button={
                            <div
                                className={`m-auto rounded-full bg-slate-50 text-xl w-8 h-8 p-1  flex items-center justify-center`}
                            >
                                {
                                    getCategoryById({
                                        id: categoryId,
                                    }).icon
                                }
                            </div>
                        }
                        categoryOptions={Object.values(CATEGORIES)}
                        onSelect={onCategorySelect}
                    />
                    <button
                        type="button"
                        onClick={onDeleteItem}
                        className={`text-red-500 w-7 h-7`}
                    >
                        <TrashIcon className="" />
                    </button>
                </div>
            </li>
            <EditItemDrawer
                open={openEditDrawer}
                setOpen={setOpenEditDrawer}
                onClose={() => setOpenEditDrawer(false)}
                onOpen={() => setOpenEditDrawer(true)}
                {...{
                    text,
                    checked,
                    quantity,
                    unit,
                    comments,
                    categoryId,
                    addedBy,
                    saved,
                    ...restOfItem,
                }}
            />
        </>
    );
}
