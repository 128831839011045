import { Dialog, Transition } from "@headlessui/react";
import { LightningBoltIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { LockClosedIcon } from "@heroicons/react/solid";
import Link from "components/Link";
import LoadingComponents, { SmallLoader } from "components/Loader";
import { getUserData } from "db-utils";
import useAlert from "hooks/useAlert";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PREMIUM_PAYMENT_PLANS } from "types";
import { countryPhoneCodes, decrypt, formatPhoneNumber } from "utils";
import pciLogo from "../assets/images/pci-logo.png";
import sslLogo from "../assets/images/ssl-logo.png";
import { payWithSumit } from "../firebase";
import { logEvent } from "./premium-info";

const PRICE = 5.9;
const DISCOUNT_PRICE = 3.99;

export default function CheckoutPage() {
    const [selectedPlan, setSelectedPlan] = useState(
        PREMIUM_PAYMENT_PLANS.MONTHLY
    );
    const [searchParams] = useSearchParams();
    const payMode = searchParams.get("pay");
    const isDiscount = Boolean(searchParams.get("dis"));
    const [userLoading, setUserLoading] = useState(true);
    const [showPaymentForm, setShowPaymentForm] = useState(Boolean(payMode));
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [checkoutError, setCheckoutError] = useState<string | undefined>();
    const [checkedOut, setCheckedOut] = useState(false);
    const [userId, setUserId] = useState<string | undefined | null>();
    const [isEditUserId, setIsEditUserId] = useState(false);
    const [userPhoneNumber, setUserPhoneNumber] = useState<
        string | undefined
    >();
    const [countryCode, setCountryCode] = useState<string>("972");
    const [phoneNumberError, setPhoneNumberError] = useState<
        string | undefined
    >();
    const ccToken = searchParams.get("og-token");
    const { isAlerted } = useAlert();

    const monthlyPrice = PRICE;
    const yearlyPrice = monthlyPrice * 10;

    const navigate = useNavigate();

    useEffect(() => {
        const pay = async () => {
            const savedUserId = localStorage.getItem("cc-uid");
            try {
                const email = localStorage.getItem("user-email");
                const name = localStorage.getItem("user-name");
                const isDiscount = Boolean(
                    localStorage.getItem("groceroo-pay-dis")
                );
                const paymentRes = await payWithSumit({
                    userId: savedUserId,
                    ccToken,
                    email,
                    name,
                    isDiscount,
                });

                const { status, txId, message, confirmationNumber } =
                    paymentRes.data as {
                        status: number;
                        message: string;
                        txId?: string;
                        confirmationNumber?: string;
                    };
                if (status === 200) {
                    navigate(
                        `/success-payment?phone=${savedUserId}&txId=${txId}&confirmationNumber=${confirmationNumber}`
                    );
                } else {
                    console.error(message);
                    navigate(`/failed-payment?phone=${savedUserId}`, {
                        replace: true,
                    });
                }
            } catch (e) {
                console.error(e);
                navigate(`/failed-payment?phone=${savedUserId}`, {
                    replace: true,
                });
            }
            setCheckoutLoading(false);
        };
        if (ccToken) {
            setCheckoutLoading(true);
            pay();
        }
    }, [ccToken, navigate]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const userIdInParams = searchParams.get("phone");
        const userIdInLocalStorage = decrypt(localStorage.getItem("uid"));
        if (typeof userId !== "undefined") {
        } else if (userIdInParams && !isNaN(Number(userIdInParams))) {
            setUserId(userIdInParams);
        } else if (
            userIdInLocalStorage &&
            !isNaN(Number(userIdInLocalStorage))
        ) {
            setUserId(userIdInLocalStorage);
        } else {
            setUserId(null);
        }
        setUserLoading(false);
    }, [searchParams, userId]);

    useEffect(() => {
        if (!userId) return;
        logEvent("checkout", userId, {});
    }, [userId]);

    const handlePhoneSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let phoneNumber = userPhoneNumber;
        const isIsraeliPhoneNumber = countryCode.startsWith("972");
        if (isIsraeliPhoneNumber && userPhoneNumber?.startsWith("0")) {
            phoneNumber = userPhoneNumber.slice(1);
        }

        // if (phoneNumber && isIsraeliPhoneNumber && phoneNumber.length !== 9) {
        //     setPhoneNumberError("מספר הטלפון שגוי");
        //     return;
        // }

        const userId = `${countryCode}${phoneNumber}`;

        const userData = await getUserData({
            userId,
        });

        if (!userData) {
            await isAlerted(
                "לא מצאנו את מספר הטלפון שלך, צריך קודם להירשם בבוט או לנסות מס' טלפון אחר"
            );
            return;
        }

        setUserId(userId);
        setIsEditUserId(false);
    };

    // const onCheckout = async () => {
    //     try {
    //         setCheckoutLoading(true);

    //         if (!userId) {
    //             await isAlerted("יש להזין מספר טלפון");
    //             setCheckoutLoading(false);
    //             return;
    //         }
    //         const userData = await getUserData({
    //             userId,
    //         });

    //         if (!userData) {
    //             await isAlerted(
    //                 "לא מצאנו את מספר הטלפון שלך, צריך קודם להירשם בבוט או לנסות מס' טלפון אחר"
    //             );
    //             setCheckoutLoading(false);
    //             return;
    //         }

    //         // const { premiumSignup } = userData;
    //         // if (!premiumSignup) {
    //         //     await updateUser({
    //         //         userId,
    //         //         updates: {
    //         //             premiumSignup: new Date(),
    //         //             premiumPaymentPlan: selectedPlan,
    //         //         },
    //         //     });
    //         // }

    //         const { accountType } = userData;
    //         if (accountType === ACCOUNT_TYPES.basic || true) {
    //             const paymentLink = await generatePaymentLink({
    //                 userId,
    //             });
    //             if (!paymentLink?.data) {
    //                 await isAlerted(
    //                     "שגיאה בהפעלת המנוי, אנא נסו שוב מאוחר יותר"
    //                 );
    //                 setCheckoutLoading(false);
    //                 return;
    //             }

    //             const { url: paymentURL } = paymentLink.data as { url: string };
    //             window.location.href = paymentURL;

    //             setCheckoutLoading(false);

    //             // redirect(paymentLink);

    //             // await updateUser({
    //             //     userId,
    //             //     updates: {
    //             //         // announcements: { upgradedToPlus: { sentDate: null } },
    //             //         accountType: ACCOUNT_TYPES.premiumTrial,
    //             //         premiumTrialStarted: Timestamp.now(),
    //             //     },
    //             // });
    //         } else {
    //             await isAlerted("כבר יש לך מנוי פלוס");
    //             setCheckoutLoading(false);
    //             return;
    //         }
    //     } catch (e) {
    //         console.error(e);
    //         await isAlerted(
    //             "אופס... קרתה לנו תקלה. יכולים לדבר איתנו בוואטסאפ"
    //         );
    //         setCheckoutLoading(false);
    //     }

    //     // setTimeout(() => {
    //     //     setCheckoutLoading(false);
    //     //     setCheckedOut(true);
    //     // }, 3000);
    // };

    if (userLoading) return <LoadingComponents fullPage />;

    if (checkoutLoading)
        return (
            <div className="mt-40 w-full h-full flex flex-col gap-5 items-center justify-center">
                <span className="text-green-500 text-center text-3xl font-semibold">
                    מבצע תשלום...
                </span>
                <span className="text-black text-center text-lg">
                    נא לא לסגור את העמוד
                </span>
                <SmallLoader />
            </div>
        );

    const userPhoneFormModal = (
        <Transition show={isEditUserId}>
            <Dialog
                dir="rtl"
                as="div"
                className="font-assistant relative z-50"
                onClose={() => setIsEditUserId(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                as="div"
                                className="w-full h-56 flex flex-col justify-between gap-2 max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-start align-middle shadow-xl transition-all"
                            >
                                <Dialog.Title
                                    as="div"
                                    className="flex items-center justify-center"
                                >
                                    <h3 className="text-2xl font-medium text-center pb-3">
                                        הזינו מספר טלפון
                                    </h3>
                                    <button
                                        type="button"
                                        className="h-6 w-6 absolute top-4 left-4"
                                    >
                                        <XIcon
                                            onClick={() =>
                                                setIsEditUserId(false)
                                            }
                                        />
                                    </button>
                                </Dialog.Title>
                                <form
                                    className="flex flex-col gap-2"
                                    onSubmit={handlePhoneSubmit}
                                >
                                    <div className="flex text-lg gap-1  h-12 items-center flex-row-reverse">
                                        <select
                                            onChange={(e) => {
                                                setCountryCode(e.target.value);
                                            }}
                                            className="border-2 w-20 h-full border-slate-200 px-1.5 py-2 rounded-lg focus:outline-none"
                                            value={countryCode}
                                        >
                                            {Object.entries(
                                                countryPhoneCodes
                                            ).map(
                                                ([countryCode, countryNum]) => {
                                                    return (
                                                        <option
                                                            key={countryCode}
                                                            value={countryNum}
                                                        >
                                                            {countryNum}+
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                        <input
                                            className="border-2 h-full border-slate-200 rounded-lg p-2 w-full"
                                            type="number"
                                            value={userPhoneNumber}
                                            onChange={(e) => {
                                                setUserPhoneNumber(
                                                    e.target.value
                                                );
                                                setPhoneNumberError(undefined);
                                            }}
                                        />
                                    </div>
                                    {phoneNumberError ? (
                                        <span className="text-red-500">
                                            {phoneNumberError}
                                        </span>
                                    ) : null}
                                    <button
                                        disabled={
                                            !userPhoneNumber || !countryCode
                                        }
                                        className="text-xl w-full mt-4 gap-2 font-medium border-[1.5px] border-green-500 bg-green-500 text-white rounded-lg shadow-sm px-3 py-2 flex items-center justify-center"
                                        type="submit"
                                    >
                                        אישור
                                    </button>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );

    return (
        <>
            {userPhoneFormModal}
            <div className="min-h-[85vh] h-full justify-between flex flex-col gap-5 px-3 pb-8">
                <div className="flex flex-col gap-3">
                    <h1 className="text-2xl font-bold text-center pt-1">
                        שידרוג למנוי Plus{" "}
                        <LightningBoltIcon className="h-7 w-7 inline-block text-amber-300" />
                    </h1>
                    <div className="text-lg text-center flex gap-3 mb-3 items-center justify-center">
                        {userId ? (
                            <>
                                למס' נייד:{" "}
                                <button
                                    onClick={() => setIsEditUserId(true)}
                                    className="font-poppins underline"
                                >
                                    {formatPhoneNumber({ phoneNumber: userId })}
                                </button>
                            </>
                        ) : (
                            <button
                                onClick={() => setIsEditUserId(true)}
                                className="underline"
                            >
                                יש להזין מספר טלפון
                                <PencilIcon className="h-6 w-6 mx-2 inline-block text-slate-700" />
                            </button>
                        )}
                    </div>
                    {showPaymentForm ? (
                        <div className="relative max-w-[400px] mx-auto">
                            <button
                                className="absolute top-0 left-0"
                                onClick={() => setShowPaymentForm(false)}
                            >
                                <XIcon className="h-6 w-6 inline-block text-slate-700" />
                            </button>

                            <PaymentForm
                                userId={userId}
                                isDiscount={isDiscount}
                            />
                        </div>
                    ) : (
                        <CheckoutCard
                            // selected={selectedPlan}
                            // selectMonthly={(isMonthly) =>
                            //     setSelectedPlan(
                            //         isMonthly
                            //             ? PREMIUM_PAYMENT_PLANS.MONTHLY
                            //             : PREMIUM_PAYMENT_PLANS.YEARLY
                            //     )
                            // }
                            // title="מנוי Plus"
                            // price={
                            //     selectedPlan === PREMIUM_PAYMENT_PLANS.MONTHLY
                            //         ? monthlyPrice
                            //         : yearlyPrice / 12
                            // }
                            // discount={
                            //     selectedPlan === PREMIUM_PAYMENT_PLANS.MONTHLY
                            //         ? monthlyPrice + 2
                            //         : (yearlyPrice + 2 * 10) / 12
                            // }
                            isDiscount={isDiscount}
                        />
                    )}
                </div>

                {showPaymentForm ? null : (
                    <>
                        <button
                            type="button"
                            onClick={() => {
                                if (!userId) {
                                    setIsEditUserId(true);
                                    return;
                                }
                                setShowPaymentForm(true);
                            }}
                            disabled={checkoutLoading}
                            className="text-xl w-full mx-auto max-w-[400px] gap-2  font-medium bg-green-500 text-white disabled:bg-slate-200 rounded-full shadow-sm px-3 py-3 flex items-center justify-center"
                        >
                            לתשלום מאובטח{" "}
                            <LockClosedIcon className="h-6 w-6 inline-block text-white" />
                        </button>
                        <span className="text-slate-600 text-base mt-0.5 text-center">
                            החזר כספי מלא עד 60 ימים לאחר הרכישה
                            <br /> (אבל אנחנו בטוחים שתאהבו)
                        </span>
                    </>
                )}
            </div>
        </>
    );
}

export const CheckoutCard = ({
    // selected,
    // price,
    // discount,
    isDiscount,
}: {
    // title: string;
    // price: number;
    // discount?: number;
    // selected: PREMIUM_PAYMENT_PLANS;
    // selectMonthly: (isMonthly: boolean) => void;
    isDiscount?: boolean;
}) => {
    return (
        <div
            className={`bg-white w-full max-w-[350px] mx-auto px-7 py-6 rounded-3xl shadow-sm transition-all duration-300 border-2 h-[500px] lg:h-auto flex flex-col justify-between gap-8 border-green-500/60 bg-green-50/10`}
        >
            <div>
                <h4 className="text-lg underline mb-2 font-bold">
                    המנוי כולל:
                </h4>
                <ul className="text-lg text-start list-none list-inside">
                    <li>&#8226; 3 רשימות נפרדות</li>
                    <li>&#8226; פריטים שמורים להוספה קלה</li>
                    <li>&#8226; עריכה בתוך בצ'אט</li>
                    <li>&#8226; פקודות מיוחדות</li>
                    <li>&#8226; פיצול הרשימה</li>
                    <li>&#8226; בחירת סדר קטגוריות</li>
                    <li>&#8226; תמיכה בשגיאות כתיב</li>
                    <li>&#8226; תמיכה בכמויות</li>
                    <li>&#8226; תמיכה בהערות</li>
                    <li>&#8226; ללא פרסומות וללא הגבלות שימוש</li>
                    {/* <li>&#8226; אתר תומך ונוח</li> */}
                    {/* <li>- ועוד דברים טובים</li> */}
                    {/* <li>פיצ'רים עתידיים*</li> */}
                </ul>
            </div>

            <div className="flex flex-col gap-0.5 items-start justify-start">
                <div className="text-lg flex flex-col gap-1">
                    <div className="flex flex-col gap-0.5 items-start justify-start">
                        <div className="flex gap-2 items-end justify-start font-poppins">
                            <span className="font-semibold text-green-600 text-2xl">
                                ₪
                                {isDiscount ? DISCOUNT_PRICE : PRICE.toFixed(2)}
                            </span>
                            {isDiscount ? (
                                <span className="line-through text-slate-500 text-lg font-medium">
                                    ₪{PRICE.toFixed(2)}
                                </span>
                            ) : null}
                        </div>
                        <span className="text-lg -mt-2 font-assistant">
                            לחודש
                        </span>
                    </div>
                </div>
                {/* <span className="text-sm text-center text-slate-600">
                    33% הנחה למצטרפים מוקדמים
                </span> */}
            </div>
        </div>
    );
};

// Extend the Window interface to include OfficeGuy
declare global {
    interface Window {
        OfficeGuy: any;
    }
}

const PaymentForm = ({
    userId,
    userName,
    isDiscount = false,
}: {
    userId: string | undefined | null;
    userName?: string;
    isDiscount?: boolean;
}) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [email, setEmail] = useState<string | undefined>();
    const [name, setName] = useState<string>("");
    // const [name, setName] = useState<string | undefined>(userName);

    useEffect(() => {
        // Function to dynamically load the external script
        const loadScript = (src: string, onLoad: () => void) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = onLoad;
            document.body.appendChild(script);
        };

        // Load the external script and set scriptLoaded to true when done
        loadScript("https://code.jquery.com/jquery-3.6.0.min.js", () => {
            loadScript("https://app.sumit.co.il/scripts/payments.js", () => {
                setScriptLoaded(true);
            });
        });
    }, []);

    useEffect(() => {
        // Use the OfficeGuy variable once the script is loaded
        if (scriptLoaded && window.OfficeGuy) {
            window.OfficeGuy.Payments.BindFormSubmit({
                CompanyID: "507730386",
                APIPublicKey:
                    "aBqByDylZZUS8MvAkPue2Wfo6t7SERmvmAbpgofbiU5wJlUMDN",
            });
        }
    }, [scriptLoaded]);

    useEffect(() => {
        if (userId) {
            localStorage.setItem("cc-uid", userId);
        } else {
            localStorage.removeItem("cc-uid");
        }
    }, [userId]);

    useEffect(() => {
        if (name) {
            localStorage.setItem("user-name", name);
        } else {
            localStorage.removeItem("user-name");
        }
    }, [name]);

    useEffect(() => {
        if (email) {
            localStorage.setItem("user-email", email);
        } else {
            localStorage.removeItem("user-email");
        }
    }, [email]);

    useEffect(() => {
        if (isDiscount) {
            localStorage.setItem("groceroo-pay-dis", "1");
        } else {
            localStorage.removeItem("groceroo-pay-dis");
        }
    }, [isDiscount]);

    return (
        <>
            <form
                data-og="form"
                className="flex flex-col gap-3 items-center justify-center max-w-[400px] mx-auto"
            >
                <div className="og-errors text-red-500"></div>

                <div className="w-full flex flex-col">
                    <label htmlFor="cc-num">מספר כרטיס אשראי</label>
                    <input
                        className="cc-input"
                        type="tel"
                        inputMode="numeric"
                        id="cc-num"
                        maxLength={20}
                        data-og="cardnumber"
                        autoComplete="cc-number"
                        required
                        placeholder="0000 0000 0000 0000"
                        dir="rtl"
                    />
                </div>
                <div className="flex w-full gap-4">
                    <div className="w-full">
                        <label htmlFor="cc-exp-month">תוקף</label>
                        <div className="w-full flex flex-row-reverse items-center">
                            <input
                                type="tel"
                                placeholder="MM"
                                id="cc-exp-month"
                                name="cc-exp-month"
                                maxLength={2}
                                dir="rtl"
                                autoComplete="cc-exp-month"
                                x-autocompletetype="cc-exp-month"
                                required
                                minLength={2}
                                className="focus:outline-none px-1 w-full bg-green-50 py-2 rounded-e-md"
                                data-og="expirationmonth"
                            />
                            <span className="px-1 bg-green-50 py-2 text-slate-300">
                                /
                            </span>
                            <input
                                type="tel"
                                placeholder="YY"
                                maxLength={2}
                                dir="rtl"
                                id="cc-exp-year"
                                name="cc-exp-year"
                                required
                                autoComplete="cc-exp-year"
                                x-autocompletetype="cc-exp-year"
                                data-og="expirationyear"
                                className="focus:outline-none px-1 w-8 bg-green-50 py-2 rounded-s-md"
                            />
                        </div>
                    </div>

                    <div className="w-full flex flex-col">
                        <label>cvv</label>
                        <input
                            type="tel"
                            placeholder="000"
                            maxLength={4}
                            required
                            className="cc-input"
                            autoComplete="cc-csc"
                            data-og="cvv"
                            id="cvv"
                            name="cvv"
                            dir="rtl"
                        />
                    </div>
                </div>

                {/* <div className="w-full">
                    <label>ת.ז. של בעל הכרטיס</label>
                    <input
                        type="tel"
                        placeholder="123456789"
                        className="cc-input"
                        // required
                        dir="rtl"
                        data-og="citizenid"
                    />
                </div> */}
                <div className="w-full">
                    <label>שם לחשבונית (רשות)</label>
                    <input
                        type="text"
                        placeholder="ישראל ישראלי"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="cc-input"
                        dir="rtl"
                    />
                </div>
                <div className="w-full">
                    <label>אימייל למשלוח חשבונית (רשות)</label>
                    <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="your@email.com"
                        value={email}
                        className="cc-input"
                    />
                </div>
                <span className="text-xl font-semibold mt-7">
                    תשלום
                    {isDiscount ? (
                        <span className="mx-1 line-through text-slate-400">
                            ₪{PRICE.toFixed(2)}
                        </span>
                    ) : null}
                    <span className="mx-1">
                        ₪{isDiscount ? DISCOUNT_PRICE : PRICE.toFixed(2)}
                    </span>
                    לחודש
                </span>
                <input
                    type="submit"
                    className="mt-2 cursor-pointer text-xl w-full mx-auto max-w-[400px] gap-2  font-medium bg-green-500 text-white disabled:bg-slate-200 rounded-full shadow-sm px-3 py-3 flex items-center justify-center"
                    disabled={!userId}
                    value="לתשלום"
                />
                <span className="text-slate-600 text-base mt-0.5 text-center">
                    החזר כספי מלא עד 60 ימים לאחר הרכישה
                </span>
                <span className="text-slate-600 text-base mt-0.5 text-center">
                    השימוש בתוכנה מהווה הסכמה
                    <Link to="/terms-of-service" className="underline mx-1">
                        לתנאי השימוש
                    </Link>
                    <Link to="/privacy" className="underline mx-1">
                        ומדיניות הפרטיות
                    </Link>
                    של האתר
                </span>
            </form>
            <div className="mt-10 flex flex-col gap-2 items-center justify-center">
                <div className="flex gap-10 justify-center">
                    <img className="w-10" src={sslLogo} alt="SSL logo" />
                    <img className="w-10" src={pciLogo} alt="pci logo" />
                </div>
                <span className="mx-auto text-center text-xs text-slate-700">
                    תשלום מאובטח ע"י חברת סאמיט בע"מ
                </span>
            </div>
        </>
    );
};
