import { useLayoutEffect } from "react";
import { isBrowser } from "react-device-detect";
import { LoaderFunction, json } from "react-router";
import { shareGrocerooURLDesktop, sharedGrocerooURLMobile } from "../constants";

export interface SahreLoaderData {
    senderPhoneNumber?: string;
}

export const loader: LoaderFunction = async ({ params }) => {
    const { senderPhoneNumber } = params;

    return json<SahreLoaderData>({ senderPhoneNumber });
};

export default function Share() {
    useLayoutEffect(() => {
        window.location.replace(
            encodeURI(
                isBrowser ? shareGrocerooURLDesktop : sharedGrocerooURLMobile
            )
        );
    }, []);

    return (
        <div
            dir="ltr"
            className="font-poppins flex flex-col gap-20 text-center justify-center  text-3xl w-full h-screen"
        >
            <div className="mt-40 lg:mt-40">Share Groceroo ❤️</div>
            <div className="font-assistant text-lg mt-60 lg:mt-40">
                <h6>
                    אם לא קורה כלום, לחצו{" "}
                    <a
                        href={
                            isBrowser
                                ? shareGrocerooURLDesktop
                                : sharedGrocerooURLMobile
                        }
                        className="underline text-green-500"
                    >
                        כאן
                    </a>
                </h6>
            </div>
        </div>
    );
}
